"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchProvidersByProcedureParams = exports.fetchPossibleProvidersByProcedureParams = exports.fetchProvidersParams = void 0;
exports.fetchProvidersParams = {
    sort: 'hospitals.name',
    'page[size]': 100,
};
exports.fetchPossibleProvidersByProcedureParams = {
    include: 'physicians,product_offering',
    sort: 'hospitals.name',
    status: 'active,inactive',
    'page[size]': 100, // query joins other records so use small page size
};
exports.fetchProvidersByProcedureParams = {
    sort: 'hospitals.name',
    'page[size]': 100,
};
