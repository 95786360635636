import { formatData } from 'app/utils/reducerUtils';

const nullState = Object.freeze({
  physicians: [],
  physician: null,
  loading: false,
});

export default (state = nullState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case 'RECEIVE_PHYSICIANS': {
      const newPhysicians = action.physicians.map((physician) => {
        physician = formatData(physician);
        physician.name = `${physician.name.prefix} ${physician.name.first} ${physician.name.last}`;

        return physician;
      });

      // upsert the mapped data to state
      const physicians = action.append
        ? [...state.physicians, ...newPhysicians]
        : newPhysicians;

      return { ...state, physicians };
    }

    case 'RECEIVE_PHYSICIAN':
      return {
        ...state,
        physician: formatData(action.physician),
        loading: false,
      };

    case 'RECEIVE_PHYSICIAN_LOADING':
      return { ...state, loading: action.physicianLoading };

    default:
      return state;
  }
};
